<h2 class="mt-5 mb-0 eveResoure GNewsResoEve SpotEveRes">
    Resources</h2>
<div class="col-12 tab-pane fade show active pt-2 ResoGDiv" id="resource-tab1" role="tabpanel" aria-labelledby="home-tab"  >
    <div class="row d-md-flex GNewsReEDiv" *ngIf="ResourcesLoaded">
        <div class="col-md-12 pb-5" *ngFor="let resourceData of ResourcesDatas">

            <a target="_blank" href='{{resourceData.RouteURL}}' >
            <img class="resources-img-h pb-2 pr-5" [src]="resourceData.ImageUrl" alt="resource image">
            </a>
            <p class="para-2 mb-0 CatTag">{{resourceData.Tag}}</p>
            <a target="_blank" href='{{resourceData.RouteURL}}' class="custom-tooltip" >
                <span class="titleBox mt-2"> {{resourceData.WhitePaperTitle}} </span> 
            <h4 class="ResoTitleTag">{{resourceData.WhitePaperTitle}}</h4>
            </a>
            <p class="para-3 text-capitalize">{{resourceData.ResourceType}}</p>

        </div>
    </div>
</div>
