<h2 class="mb-0 GNewsResoEve SpotEveRes">
    Resources</h2>
<div class="GNewsReEDiv ResoGDiv" *ngIf="loaded">
    <!-- <div class="col-md-12 pt-3 cursor-p" routerLink='/resources/{{resDatas.ResourceType +"s" | lowercase }}/{{resDatas.RouteURL}}/{{resDatas.ID}}'> -->
      <a target="_blank"  href='{{resDatas.RouteURL}}' >
        <img class="resources-img-h pb-2 pr-5" [src]='resDatas.ImageUrl' alt="resource image">
      </a>
        <p class="para-2 mb-0 CatTag">{{resDatas.Tag}}</p>
        
        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
        <a target="_blank"  href='{{resDatas.RouteURL}}' >
        <h4 class="ResoTitle">{{resDatas.WhitePaperTitle}}</h4>
        </a>
        <p class="para-3 text-capitalize">{{resDatas.ResourceType}}</p>

    <!-- </div> -->
</div>