<p class="mb-0 BreadcbTop">
    <a href="" routerLink="/">Home</a> > 
    <a href="" routerLink="/events">Events</a> > 
    <a  class="para-2-c-1">Webinars</a>
</p>
<h1 class="mb-0">Webinars</h1>
<div class="row">
    <div class="col-md-12">
        <ul class="nav nav-tabs confTab" id="myTab" role="tablist">
            <li class="nav-item w-50 text-center">
                <a class="nav-link active pl-2 pr-2 text-center" [class.active]="lastString == 'live-webinars'" id="live-tab" data-toggle="tab" href="#webinars-tab1" routerLink="/events/live-webinars" role="tab" aria-controls="live" aria-selected="true">Live Webinars</a>
            </li>
            <li class="nav-item w-50 text-center">
                <a class="nav-link pl-md-3 pr-md-4 text-center" [class.active]="lastString == 'on-demand-webinars'" id="ondemand-tab" data-toggle="tab" href="#webinars-tab2" routerLink="/events/on-demand-webinars" role="tab" aria-controls="ondemand" aria-selected="false">On-Demand Webinars</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade pt-2" id="webinars-tab1" [ngClass]="{'active': lastString == 'live-webinars', 'show': lastString == 'live-webinars'}" role="tabpanel" aria-labelledby="live-tab">
                <app-live-webiners></app-live-webiners>
            </div>
            <div class="tab-pane pt-2" id="webinars-tab2" [ngClass]="{'active': lastString == 'on-demand-webinars', 'show': lastString == 'on-demand-webinars'}" role="tabpanel" aria-labelledby="ondemand-tab">
                <app-ondemand-webiners></app-ondemand-webiners>
            </div>
        </div>
    </div>
</div>