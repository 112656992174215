<div class="row" *ngIf='ConferenceLoaded==false'>
    <div class="col-md-4 pb-5" *ngFor="let d of dumArray">
        <list-content-loader></list-content-loader>
    </div>
</div>
<div class="row d-md-flex WrapArt" *ngIf="ConferenceLoaded">
    <div class="col-md-4 pb-5" *ngFor="let UpcomingConferences of UpcomingConferenceDatas | paginate : {
        id:'UpcomingConferencePaginatorID',
        itemsPerPage: pageSize,
        currentPage: page,
        totalItems: count
      };
let i = index
" [class.active]="i == currentIndex">
        <!-- <div class="cursor-p" routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}">  -->
            <!-- <div class="cursor-p" routerLink="{{UpcomingConferences.URL}}"> -->
                <!-- <a  routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}" target="_blank" > -->
            <a href='https://{{envSiteName}}/{{UpcomingConferences.EventDifferentType | lowercase }}/{{UpcomingConferences.RouteURL}}' target="_blank"  class="custom-tooltip">       
            <img class="img-fluid pb-2 image-hight-news " [src]="UpcomingConferences.ImageUrl" alt="conference image">
            <span class="titleBox mt-2"> {{UpcomingConferences.Name}} </span> 
            <h4 class="text-height-1 height-25 MainTitle" [innerHTML]="UpcomingConferences.Name"></h4>
              </a>
            <p class="para-2 mb-1 sponDate"> {{UpcomingConferences.DateWithStartTime}} <span *ngIf='UpcomingConferences.DateWithStartTime!="" && UpcomingConferences.Country!=""'>|</span> {{UpcomingConferences.Country | firstWord}}
                <span *ngIf='UpcomingConferences.IsSponcered==true'>| <font>Sponsored</font></span>
            </p>
            <p class="para-1 text-justify text-height-4 DescPara" [innerHTML]="UpcomingConferences.Details"></p>
         <!-- <a class="para-2 para-2-c-2 " target="_blank" routerLink="{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}">Register Now</a>  -->
         <a class="para-2 para-2-c-2 Read_More" target="_blank" href='https://{{envSiteName}}/{{UpcomingConferences.EventDifferentType | lowercase }}/{{UpcomingConferences.RouteURL}}'>Register Now</a> 

            <!-- <a class="para-2 para-2-c-2 " href="events/upcoming-conferences/{{UpcomingConferences.RouteURL}}/{{UpcomingConferences.EventID}}" target="_blank">Register Now</a> -->
        <!-- </div> -->
    </div>
</div>
<div class="row">
    <div class="col-12 p-0 text-center pgntn mt-md-0" *ngIf="count>9">
        <pagination-controls id="UpcomingConferencePaginatorID" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
</div>